<script setup>
import { computed, onMounted } from 'vue'
import { useGameStore } from '../../stores/game'
const gameStore = useGameStore()
import Hublot from './hublot.vue'
import Grid from './grid.vue'
</script>
<template lang="pug">
.w-full
  .mainScreen.w-full.relative
    .w-full.relative
      div.w-full(v-if="gameStore.step === 4")
        .flex.justify-between.absolute.text-center(class="mt-[-5vh] w-full px-[8vw] 2xl:px-[13vw] 2xl:mt-0")
          img(src="../../assets/game/compte.png" class="w-1/4 2xl:w-[25vw] 2xl:h-[12vh] ")
          img(src="../../assets/game/triple.png" class="w-1/4 2xl:w-[25vw] 2xl:h-[12vh]")
      .w-full.absolute(class=" flex justify-center items-center top-[4vh] md:top-[5vh] lg:top-0")
        Hublot
      .mx-auto.flex.min-h-screen.items-center.justify-center(class="w-[90vw] mt-[7vh] 2xl:mt-0 ")
        .w-full(class=" rounded-xl bg-gradient-to-r from-yellow-100  via-yellow-300 to-yellow-500 p-[1.7vw] lg:p-[0.7vw]")
          .items-center.justify-center(class="bg-[#0e1b5d] rounded-md h-[67vh] lg:h-[75vh] ")
            Grid(class="flex mx-auto items-center justify-center")
</template>

<style scoped>
.mainScreen{
  background-image: url('../../assets/game/background.png') ;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
.counterScreen {
	border-top: 10vw solid #0e1b5d; 
	border-left: 5vw solid transparent; 
	border-right: 5vw solid transparent; 
}
</style>