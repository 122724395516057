<template lang="pug">
.start.w-full.relative
  .ourGrid.w-full.flex.justify-center
    .grid.grid-rows-4.grid-flow-col(class=" gap-y-[5vh] md:gap-y-[7vh] lg:gap-y-[5vh] xl:gap-y-[7vh] 2xl:gap-y-[6vh] xl:p-0 2xl:mt-0 2xl:p-[2.1vw]  select-none")
        .scene( v-for="(item, index) in orderedGame" :key="index")
          .cube.second
            .cube-face.face-front(class="w-full h-[13vh] lg:h-[13vh] lg:w-[40vw]")
              //bloqué cl l'index des champs qui non pas d'élement
              p.rounded-full.border.text-white(v-if="item && item.answer !== undefined && item.answer !== null && item.answer !== ''" class="h-[8vh] w-[4vw] 4k:h-[9vh] 4k:w-[5vw] flex items-center justify-center border-blue-500 drop-shadow-md bg-gradient-to-r from-[#314FAE] to-[#001CA2] text-xs sm:text-sm lg:text-xl xl:text-2xl 2xl:text-4xl 4k:text-7xl font-bold shrink-0") {{ item.number }}
              p.rounded-full.text-white(v-else)
            .cube-face.face-back
            .cube-face.face-right.w-full(id="right-faace-{{ index }}")
              div.w-full.flex.items-center.justify-center.px-5(class="font-bold")
                p.text-white.rotate-180.uppercase(class="w-[70%] text-left text-xs sm:text-sm lg:text-xl xl:text-xl 2xl:text-4xl 4k:text-7xl") {{ item.answer }}
                p.text-white.rotate-180(class="pr-[1vw] w-[30%] text-right text-xs sm:text-sm lg:text-xl xl:text-2xl 2xl:text-6xl 4k:text-8xl") {{ item.point }}
            .cube-face.face-left
            .cube-face.face-top
            .cube-face.face-bottom
    .Ourimage.absolute.w-full.animate.fade-delay(class="top-[21vh] " v-show="showError" :class="getErrorImageClass")
      div.image-container.flex.flex-row.items-center.justify-center(v-if="badResponseClicks > 0")
        img(v-for="n in badResponseClicks" :key="n" :src="errorImage" class="w-[14vw] mx-[0.5vw]")
  audio#myAudio(src="" muted="muted")
</template>
    
          
<script setup>
import { orderBy } from 'lodash-es'
import { ref, reactive, computed, watch, onMounted } from 'vue'
import errorImage from '../../assets/illustration/error.png'
import badResponse from '../../assets/audios/bad-response2.mp3'
import goodSound from '../../assets/audios/goodSoundMid.mp3'
import { useGameStore } from '../../stores/game'

const gameStore = useGameStore()
const step = computed(() => gameStore.step)
let game = reactive([])

const currentIndex = ref(-1)
const responseRevealed = ref([])
const is_play = ref(false)
const showError = ref(false)
const badResponseClicks = ref(0)
const numberOfImagesToShow = ref(0)

const audio = [
  { name: 'bad response', audioUrl: badResponse },
  { name: 'good response', audioUrl: goodSound },
]

//Show error svg
const playBadResponse = (errorNumber) => {

  if (errorNumber < 4 && errorNumber != 0) {
    setErrorAndPlaySound(errorNumber);
  }

  if (errorNumber === 4) {
    setErrorAndPlaySound(1)
  }
}

const setErrorAndPlaySound = (error) => {
  badResponseClicks.value = error
  numberOfImagesToShow.value = error
  showError.value = true;
  is_play.value = true;
}

const revealOnlyError = () =>{
  const errors = gameStore.gameState.rounds[gameStore.gameState.step].errors
  badResponseClicks.value = errors == 4 ? 1 : errors
  numberOfImagesToShow.value = errors == 4 ? 1 : errors
  if (errors !== 0) showError.value = gameStore.gameState.rounds[gameStore.gameState.step].show_errors
}

// Close All error
const closeError = () => {
  console.log('Fermeture d\'erreur')
  showError.value = false 
  numberOfImagesToShow.value = 0
  badResponseClicks.value = 0
}

const getErrorImageClass = computed(() => {
  return {
    'fade-out': !showError.value,
    'fade-in': showError.value
  }
})

const orderedGame = computed(() => {
  let arr = []
  if (gameStore.gameState.rounds){
    game = gameStore.gameState.rounds[step.value].answers
    arr = game
  }
  while (arr.length < 8){
    arr.push({answer: "", point: "", number: arr.length + 1, validated: false, revealed: false })
  }

  return orderBy(arr, ['point'], ['desc'])
})

const revealDataAfterLoad = (arr) => {
  arr.forEach(item => {
    if (item.validated || item.revealed) {
      rotateCube(item, {playMusic: false})
    }
  })
}

const playGoodRsponseMusic = () => {
  const audioElement = new Audio(audio.find(item => item.name === 'good response').audioUrl)
  audioElement.play()
}

const rotateCube = (item, {playMusic}) => {
  if (playMusic) playGoodRsponseMusic()
  const index = orderedGame.value.indexOf(item)
  responseRevealed.value[index] = true
  const cubeElements = document.querySelectorAll('.cube.second')
  const rightFaceElements = document.querySelectorAll('.cube-face.face-right')

  const mainFace = cubeElements[item.number - 1].querySelector('.cube-face.face-front')
  const rightFace = rightFaceElements[item.number - 1]

  cubeElements[item.number - 1].classList.remove('reverseRotate')
  if (!cubeElements[item.number - 1].classList.contains('rotate')) cubeElements[item.number - 1].classList.add('rotate')

  setTimeout(() => {
    mainFace.innerHTML = rightFace.innerHTML
    mainFace.style.background = 'linear-gradient(90deg, #1430DD 50%, #2F97FA 63.88%)'
  }, 400)
}

const removeRotate = (item) => {
  const index = orderedGame.value.indexOf(item)
  responseRevealed.value[index] = false
  const cubeElements = document.querySelectorAll('.cube.second')
  const mainFace = cubeElements[item.number-1].querySelector('.cube-face.face-front')
  cubeElements[item.number-1].classList.remove('rotate')
  if (!cubeElements[item.number - 1].classList.contains('reverseRotate')) cubeElements[item.number-1].classList.add('reverseRotate')

  setTimeout(() => {
    mainFace.innerHTML = ""
    mainFace.appendChild(contructElement(item))
    mainFace.style.background = 'linear-gradient(90deg, #314FAE 50%, #001CA2 63.88%)'
  }, 400)
}


const contructElement = (item) => {
  const p = document.createElement('p')
  p.className = item && item.answer !== undefined && item.answer !== null && item.answer !== '' ?  "rounded-full text-white h-[8vh] w-[4vw] flex items-center justify-center border border-blue-500 drop-shadow-md bg-gradient-to-r from-[#314FAE] to-[#001CA2] text-xs sm:text-sm lg:text-xl xl:text-2xl 2xl:text-4xl font-bold shrink-0" : "rounded-full"
  p.textContent = item && item.answer !== undefined && item.answer !== null && item.answer !== '' ? item.number : ''
  return p
}

const moveBackwardOrForwardGameState = (arr) => {
  arr.forEach((item, id) => {
    (!item.validated || !item.revealed) ? responseRevealed.value[id] ? removeRotate(item) : "" : rotateCube(item, {playMusic: false})
  })
}

const goToNextRound = () => {
  orderedGame.value.forEach(item => {
    removeRotate(item)
  })
}


window.playBadResponse = playBadResponse
window.closeError = closeError
window.rotateCube = rotateCube
window.revealDataAfterLoad = revealDataAfterLoad
window.moveBackwardOrForwardGameState = moveBackwardOrForwardGameState
window.closeError = closeError
window.revealOnlyError = revealOnlyError
window.goToNextRound = goToNextRound

onMounted(() => {
  revealOnlyError()
  revealDataAfterLoad(gameStore.gameState.rounds[gameStore.step].answers)
})

watch(showError, (newValue) => {
  if(newValue == true) {
    setTimeout(() => {
      const audioElement = new Audio(audio.find(item => item.name === 'bad response').audioUrl);
      audioElement.play();
    }, 200)
  }
})
</script>
  
  <style scoped>
  
  .rotate-180{
    rotate: 180deg
  }
  /* Cube */
  .scene {
      height: 1vh;
      margin: 2.1vw;
      perspective: 3000px ;
    }
  
    /* Style for each 3D cube */
    .cube {
      width: 40vw;
      height: 13vh;
      position: relative;
      transform-style: preserve-3d;
      transform: translateZ(-10px);
      /* transform: translateZ(1px); */
      transition: transform 1s;
      background: none
    }
  
    .cube.second.rotate {
      animation: rotateX linear  0.5s  ;
    }

    .cube.second.reverseRotate {
      animation: reverseRotateX linear  0.5s  ;
    }
  
    .cube-face {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      /* font-size: 40px; */
      font-weight: bold;
      text-align: center;
    }
  
    .face-front {
      background: none;
      transform: rotateY(0deg) translateZ(5px);
      border-radius: 15px;
      border: 3.8px solid #041F44;
      background: linear-gradient(90deg, #314FAE 50%, #001CA2 63.88%);
      transform-style: preserve-3d;
      transition: background 0.5s;
    }

    @media screen and (min-width: 1536px) {
      .face-front {
        border-radius: 25px;
      }
    }

    @media screen and (min-width: 3000px) {
      .face-front {
        border-radius: 35px;
      }
    }
  
    .face-back {
      background: none;
      transform: rotateY(90deg) translateZ(10px);
      width: 15vw;
      height: 10vw;
      transform-style: preserve-3d;
    }
  
    .face-right {
      background: none;
      transform: rotateX(180deg) translateZ(5px);
      width: 40vw;
      height: 13vh;
      border-radius: 15px;
      border: 3.8px solid #041F44;
      background: linear-gradient(90deg, #1430DD 50%, #2F97FA 63.88%);
    }

    @media screen and (min-width: 1536px) {
      .face-right {
        border-radius: 25px;
      }
    }

    @media screen and (min-width: 3000px) {
      .face-right {
        border-radius: 35px;
      }
    }
  
    .face-left {
      background: none;
      transform: rotateY(-90deg) translateZ(10px);
      width: 10px;
      height: 200px;
    }
  
    .face-top {
      border-radius: 15px;
      border: 5px solid #041F44;
      background: linear-gradient(90deg, #314FAE 50%, #001CA2 63.88%);
      transform: rotateX(90deg) translateZ(-5.6vw);
      width: 39.5vw;
      height: 0.5vh;
      transform-style: preserve-3d;
    }
  
    .face-bottom {
      border-radius: 15px;
      border: 5px solid #041F44;
      background: linear-gradient(90deg, #314FAE 50%, #001CA2 63.88%);
      transform: rotateX(-90deg) translateZ(-4px);
      width: 39.5vw;
      height: 10px;
    }
    
    @keyframes rotateX {
      0% {
        transform: rotateX(0deg);
      }
      100% {
        transform: rotateX(180deg);
      }
    }

    @keyframes reverseRotateX {
      0% {
        transform: rotateX(180deg);
      }
      100% {
        transform: rotateX(0deg);
      }
    }
  
   /*=== FADE OUT  ===*/
    .fade-out {
    opacity: 0;
    transition: opacity 2s ease-out;
    -webkit-animation-name: fade-in;
    animation-name: fade-in;
    }
    
    @-webkit-keyframes fade-out {
    from {
      opacity: 1;
    }
  
    to {
      opacity: 0;
    }
  }
  @keyframes fade-out {
    from {
      opacity: 1;
    }
  
    to {
      opacity: 0;
    }
  }
  
   /*=== FADE OUT END  ===*/
  
   /*=== FADE IN  ===*/
  @-webkit-keyframes fade-in {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  .fade-in {
    -webkit-animation-name: fade-in;
    animation-name: fade-in;
  }
  /*=== Trigger  ===*/
  .animate {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  .fade-delay {
    -webkit-animation-delay: 0.1s;
    -moz-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }

  @media screen and (max-height: 400px) and (min-width: 768px) {
    .fade-in {
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
    }
    
  }
  
  </style>
      