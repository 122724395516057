<script setup>

  import { ref, computed } from 'vue'
  import mainscreen from '../gamebord/mainscreen.vue'
  import finalmainscreen from '../finalgamebord/finalmainscreen.vue'
  import { useGameStore } from '../../stores/game.js'
  import { useLiveGame } from '../../composables/useLiveGame.js'
  import consumer from '../../../../javascript/channels/consumer.js'

  import logo from '../logo/logo.vue'


  const gameId = window.location.search.split('=')[1]
  const gameChannel = consumer.subscriptions.create({ channel: "GameChannel", game_id: gameId }, {
    connected() {
      console.log('connected to game channel')
      this.load(gameId);
    },
    received(data) {
      window.eventBus.publish('onData', data)
      // Called when there's incoming data on the websocket for this channel
    },

    load(gameID) {
      this.perform("load", { game_id: gameID })
    }
  })
  const gameStore = useGameStore()
  const step = computed(() => gameStore.step)
  const canShowLogo = computed(() => gameStore.logo_visible)
  const initalized = computed(() => {
    const isInitialized = Object.keys(gameStore.gameState).length > 0
    console.log("game state", gameStore.gameState, isInitialized)
    return isInitialized
  })
  useLiveGame()

</script>

<template lang="pug">
div
  .start.w-full(v-if='initalized')
    mainscreen(v-if="step <= 4 && !canShowLogo")
    finalmainscreen(v-if="step > 4 && !canShowLogo")
    logo(v-if="canShowLogo")
</template>


