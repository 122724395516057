<template lang="pug">
.hublot
  svg(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 373 134" fill="none" class="relative w-[30vw] h-[15vh] ")
    path(d="M315.407 128.804L318.802 128.803L319.991 125.622L362.587 11.6407L365.058 5.02968L358 5.02968L187.857 5.02968L187.854 5.02968L14.9973 5.1277L7.6904 5.13185L10.4719 11.8886L57.4127 125.915L58.6619 128.95L61.9436 128.948L315.407 128.804Z" fill="url(#paint0_radial_149_359)" stroke="url(#paint1_linear_149_359)" stroke-width="9.79362")
    defs
      radialGradient(id="paint0_radial_149_359" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(182.37 122.476) rotate(179.83) scale(250.857 111.029)")
        stop(stop-color="#0056BF")
        stop(offset="0.14" stop-color="#01429F")
        stop(offset="0.29" stop-color="#033082")
        stop(offset="0.42" stop-color="#042571")
        stop(offset="0.51" stop-color="#05226B")
        stop(offset="0.62" stop-color="#062060")
        stop(offset="0.84" stop-color="#091D4F")
        stop(offset="1" stop-color="#0A1C4A")
      linearGradient(id="paint1_linear_149_359" x1="188.196" y1="124.285" x2="187.856" y2="9.51224" gradientUnits="userSpaceOnUse")
        stop(stop-color="#314FAE")
        stop(offset="0.333333" stop-color="#062063")
        stop(offset="0.666667" stop-color="#508BE5")
        stop(offset="0.8125" stop-color="#3A6AB3")
        stop(offset="1" stop-color="#1E4072")
    text(x="50%", y="50%", font-size="80" font-weight="bold" fill="white" text-anchor="middle" dy=".5em") {{ score }}
</template>

<script setup>
import { ref, computed } from 'vue'
import { useGameStore } from '../../stores/game'
const gameStore = useGameStore()
const score = computed(() => gameStore.score)
</script>