import { useGameStore } from "../stores/game"

export const useLiveGame = () => {
  const gameStore = useGameStore()

  window.eventBus.subscribe("onData", (data) => {
    console.log("data", data)
    if (data.action == "start_game") updateFirst(data)
    if (data.action == "add_error") addError(data)
    if (data.action == "reveal_answer") revealAnswer(data)
    if (data.action == "remove_error") removeError(data)
    if (data.action == "undo") undoState(data)
    if (data.action == "next_round") goToNextRound(data)
    if (data.action == "score_totalized") updateState(data)
    if (data.action == "update_mode") updateState(data)
    if (data.action == "hide_logo") hideLogo(data)
    if (data.action == "show_pulse") showPulseBlock(data)
    if (data.action == "show_response") pulseAndShowResponse(data)
    if (data.action == "show_score") displayFinalScore(data)
    if (data.action == "update_score") updateState(data)
    if (data.action == "refresh_score_family") updateFamilyScore(data)
    if (data.action == "hide_family_logo") hideFamilyLogo(data)
  })

  const updateFamilyScore = (data) => {
    gameStore.family_a_score = data.family_a.score
    gameStore.family_b_score = data.family_b.score
  }

  const updateState = (data) => {
    gameStore.step = data.game_state.step
    gameStore.gameState = data.game_state
    gameStore.logo_visible = gameStore.gameState.show_logo
    gameStore.family_screen_logo_visible = gameStore.gameState.show_family_logo
    if (gameStore.step <= 4) {
      gameStore.score = data.game_state.rounds[gameStore.step].score
    } else {
      gameStore.score = data.game_state.finals.score
    }
  }

  const hideLogo = (data) => {
    updateState(data)
    if (data.game_state.step > 4 && !data.game_state.show_logo) {
      window.displayDataAfterLoad()
    }
  }

  const hideFamilyLogo = (data) => {
    updateState(data)
  }

  const updateFirst = (data) => {
    gameStore.step = data.game_state.step
    gameStore.gameState = data.game_state
    gameStore.logo_visible = gameStore.gameState.show_logo
    gameStore.family_screen_logo_visible = gameStore.gameState.show_family_logo
    if (gameStore.step <= 4) {
      gameStore.score = data.game_state.rounds[gameStore.step].score
    } else {
      gameStore.score = data.game_state.finals.score
      const index = data.game_state.finals.player_index
      const player_index_name = data.game_state.finals.players[index]
      const player = data.game_state.finals[player_index_name]
      const position = 5 * index + player.question_index
      window.showFinalPulse(position)
    }
  }

  const addError = (data) => {
    gameStore.is_it_the_first = false
    updateState(data)
    window.playBadResponse(data.game_state.rounds[data.game_state.step].errors)
    if (data.game_state.rounds[data.game_state.step].errors == 0) {
      window.closeError()
    }
  }

  const revealAnswer = (data) => {
    gameStore.is_it_the_first = false
    const playMusic = data.game_state.mode === "playing"
    updateState(data)
    if (window.rotateCube == "undefined") return
    window.rotateCube(
      gameStore.gameState.rounds[data.game_state.step].answers[data.number - 1],
      { playMusic }
    )
  }

  const removeError = (data) => {
    gameStore.is_it_the_first = false
    updateState(data)
    window.closeError()
  }

  const undoState = (data) => {
    gameStore.is_it_the_first = false
    updateState(data)
    if (data.game_state.step <= 4) {
      window.moveBackwardOrForwardGameState(
        gameStore.gameState.rounds[data.game_state.step].answers
      )
    } else {
      window.undoState()
      const index = data.game_state.finals.player_index
      const player_index_name = data.game_state.finals.players[index]
      const player = data.game_state.finals[player_index_name]
      const position = 5 * index + player.question_index
      window.showFinalPulse(position)
    }
  }

  const goToNextRound = (data) => {
    gameStore.is_it_the_first = false
    updateState(data)
    if (data.game_state.step <= 4) {
      window.goToNextRound()
    }
  }

  const showPulseBlock = (data) => {
    gameStore.is_it_the_first = false
    updateState(data)
    window.showItemsAndPulse(data.position)
  }

  const pulseAndShowResponse = (data) => {
    gameStore.is_it_the_first = false
    updateState(data)
    window.displayResponse(data.position)
  }

  const displayFinalScore = (data) => {
    gameStore.is_it_the_first = false
    updateState(data)
    window.showScore(data.position)
  }

}
