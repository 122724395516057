import { defineStore } from 'pinia';

export const useGameStore = defineStore('game', {
  state: () => ({
    step: 1,
    score: 0,
    gameState: {},
    is_it_the_first: false,
    logo_visible: false,
    family_a_score: 0,
    family_b_score: 0,
    family_screen_logo_visible: true
  }),
})