<template lang="pug">
.start.w-full
  .ourGrid.w-full.grid.grid-rows-5.grid-flow-col(class="gap-y-[5vh] 2xl:gap-y-[2vh] py-[4vh] px-[5vw] ")
    .w-full.flex.rounded.border.relative(class="max-w-[35vw] mx-auto border-transparent overflow-hidden" v-for="(i, index) in final" :key="index" )
      .flex.justify-between.items-stretch.w-full.relative.animated-border.items-center.overflow-hidden(class="{ 'border-green-500': isPulseButtonActive, 'border-red-500': isResponseButtonActive, 'border-pink-500': isScoreButtonActive, 'border-orange-700': isBackgroundButtonActive }")
        .card-left.bg-black.text-white.relative(class="w-[86vw] py-[4vh] border-y-4 border-l-4 border-[#233a8c] rounded-l-xl" :class="{ 'bg-custom': !i.is_background_hidden }")
          div.absolute.top-0.left-0.flex.items-center.justify-center(class="w-full h-full" )
            span.font-bold.uppercase(class="text-xs sm:text-sm lg:text-xl xl:text-2xl 2xl:text-4xl 4k:text-7xl" :class="i.is_response_hidden ? 'text-black' : 'text-white'") {{ i.response }}
        .card-right.bg-black.text-white.text-center.relative(class="w-[14vw] py-[4vh] border-y-4 border-r-4 border-[#233a8c] rounded-r-xl" :class="{ 'bg-custom': !i.is_background_hidden }")
          div.absolute.top-0.left-0.flex.items-center.justify-center(class="w-full h-full" )
            span.font-bold.uppercase(class="text-xs sm:text-sm lg:text-xl xl:text-2xl 2xl:text-5xl 4k:text-8xl" :class="i.is_score_hidden ? 'text-black' : 'text-white'") {{ i.score }}
        carette(:is-enable="i.is_displayed" :do-animation="i.do_animation")
      .absolute.top-0.left-0.w-full.h-full.z-20
        .flex.justify-between.items-stretch.w-full.h-full
          .card-fake-left(class="w-[87%] order-y-4 border-l-4 border-[#233a8c] rounded-l-xl")
          .card-fake-between(class="w-[3%] bg-[#233a8c] h-full z-20 ")
          .card-fake-right(class="w-[13%] border-y-4 border-r-4 border-[#233a8c] rounded-r-xl")
</template>
  
<script setup>
import carette from './carette.vue'
import { ref, reactive, onMounted, computed } from 'vue'
import slideSound from '../../assets/audios/flipSound.mp3'
import goodSound from '../../assets/audios/goodSoundShort.mp3'
import BestAnswerSound from '../../assets/audios/backgroundSound.mp3'
import zeroSound from '../../assets/audios/zeroSound.mp3'
import duplicateSound from '../../assets/audios/duplicateSound.mp3'
import { useGameStore } from '../../stores/game'
  const final = reactive([
    { response: '', is_displayed: false , do_animation: false, is_score_hidden: true, is_response_hidden: true, is_background_hidden: true, score: 0},
    { response: '', is_displayed: false , do_animation: false, is_score_hidden: true, is_response_hidden: true, is_background_hidden: true, score: 0 },
    { response: '', is_displayed: false , do_animation: false, is_score_hidden: true, is_response_hidden: true, is_background_hidden: true, score: 0 },
    { response: '', is_displayed: false , do_animation: false, is_score_hidden: true, is_response_hidden: true, is_background_hidden: true, score: 0},
    { response: '', is_displayed: false , do_animation: false, is_score_hidden: true, is_response_hidden: true, is_background_hidden: true, score: 0},
    { response: '', is_displayed: false , do_animation: false, is_score_hidden: true, is_response_hidden: true, is_background_hidden: true, score: 0},
    { response: '', is_displayed: false , do_animation: false, is_score_hidden: true, is_response_hidden: true, is_background_hidden: true, score: 0},
    { response: '', is_displayed: false , do_animation: false, is_score_hidden: true, is_response_hidden: true, is_background_hidden: true, score: 0 },
    { response: '', is_displayed: false , do_animation: false, is_score_hidden: true, is_response_hidden: true, is_background_hidden: true, score: 0},
    { response: '', is_displayed: false , do_animation: false, is_score_hidden: true, is_response_hidden: true, is_background_hidden: true, score: 0 }
  ])

  const audio = [
    {name: 'slide', audioUrl: slideSound },
    {name: 'goodAnswer', audioUrl: goodSound },
    {name: 'bestAnswer' , audioUrl: BestAnswerSound},
    {name: 'zero', audioUrl: zeroSound},
    {name: 'duplicateSound', audioUrl: duplicateSound},
  ]

  const gameStore = useGameStore()

  const undoState = () =>{
    displayDataAfterLoad()
  }

  const setFinalCurrentData = (position) => {
    const player = gameStore.gameState.finals.players[gameStore.gameState.finals.player_index]
    const question = gameStore.gameState.finals.questions[position%5].question
    const response = gameStore.gameState.finals[player].answers[question].answer == "." ? "" : gameStore.gameState.finals[player].answers[question].answer
    const point = gameStore.gameState.finals[player].answers[question].point
    const position_in = gameStore.gameState.finals[player].answers[question].position
    const revealed = gameStore.gameState.finals[player].answers[question].revealed
    const show_pulse = gameStore.gameState.finals[player].answers[question].show_pulse
    const show_response = gameStore.gameState.finals[player].answers[question].show_response

    return {
      response,
      point,
      position_in,
      revealed,
      show_pulse,
      show_response
    }
  }

  //show pulse image
  const showItemsAndPulse = (position) => {
    final[position].response = setFinalCurrentData(position).response
    final[position].is_displayed = true
  }

  const showFinalPulse = (position) => {
    const current_data = setFinalCurrentData(position)
    final[position].response = current_data.response
    if (current_data.revealed) {
      displayResponse(position, false, 0)
      showScore(position, false)
    }
    if (current_data.revealed == false && current_data.show_response) {
      displayResponse(position, false, 0)
      final[position].is_displayed = true
    }
    if (current_data.show_response == false && current_data.show_pulse) {
      final[position].do_animation = false
      final[position].is_displayed = true
    }
    if(current_data.show_pulse == false){
      final[position].is_displayed = false
      final[position].do_animation = false
    }
  }

  const displayDataAfterLoad = () => {
    gameStore.gameState.finals.players.forEach((player) => {
      loadPlayerOneData(player)
    })
    // clearInterval(window.thisIsNotTheWayToDoIt)
  }
  const loadPlayerOneData = (player) => {
    Object.values(gameStore.gameState.finals[player].answers).forEach((values) => {
      final[values.position].response = values.revealed ? (values.answer == "." ? "" : values.answer) : ""
      final[values.position].score = values.revealed ? values.point : 0
      final[values.position].is_response_hidden = !values.revealed
      final[values.position].is_score_hidden = !values.revealed
    })
  }

//show response

  const displayResponse = (position, play_sound = true, time = 700) => {
    const audioElement = new Audio(audio.find(item => item.name === 'slide').audioUrl)
    if (play_sound) audioElement.play()
    final[position].do_animation = true
    setTimeout(() => {
      final[position].is_response_hidden = false
    }, time)
  }

//show score
  const showScore = (position, play_sound = true) => {
    final[position].score = setFinalCurrentData(position).point ? setFinalCurrentData(position).point : 0
    if (play_sound) soundWhenShowResponse(final[position].score)
    final[position].is_score_hidden = false
    final[position].is_displayed = false
    final[position].do_animation = false
  }

  const soundWhenShowResponse = (score) => {
    console.log("score", score)
    if (score == 0) {
      const audioElement = new Audio(audio.find(item => item.name === 'zero').audioUrl)
      audioElement.play()
    } else {
      const audioElement = new Audio(audio.find(item => item.name === 'goodAnswer').audioUrl)
      audioElement.play()
    }
  }

//add background
  const showBackground = (position) => {
    const audioElement = new Audio(audio.find(item => item.name === 'bestAnswer').audioUrl)
    audioElement.play()
    final[position].is_background_hidden = false
  }
  

  window.showItemsAndPulse = showItemsAndPulse
  window.showScore = showScore
  window.showBackground = showBackground
  window.undoState = undoState
  window.displayResponse = displayResponse
  window.displayDataAfterLoad = displayDataAfterLoad
  window.showFinalPulse = showFinalPulse

  onMounted(() => {
    displayDataAfterLoad()
  })
  
</script>
  
<style scoped>
.bg-custom {
  /* background: linear-gradient(56.92deg, #705304 5%, #D7B047 1.24%, #E0BE5E 4.29%, #E4C367 5.3%, #EACC77 6.32%, #F2DA8E 9.37%, #F6DF97 10.38%, #705304 30%, #F2DA8E 16.48%, #F2DA8E 20.54%, #F6DF97 26.64%, #F1D88B 28.67%, #EACC77 32.73%, #E5C56B 35.78%, #E4C367 39.84%, #DDBA57 42.89%, #CDA32F 48.98%, #CB9F28 50%, #D6B045 55.08%, #E4C367 60.16%, #EACC77 63.21%, #F2DA8E 67.27%, #F6DF97 70.32%, #F2DA8E 75.4%, #F2DA8E 77.43%, #FDF9DC 78.44%, #F9EBB6 81.49%, #F6E29F 84.54%, #F6DF97 85.55%, #EED282 87.59%, #E4C367 90.63%, #D1A838 97.74%, #CB9F28 100.79%); */
  /* background: linear-gradient(56.92deg, #CB9F28 -0.79%, #D7B047 1.24%, #D7B047 4.29%, #E4C367 5.3%, #EACC77 6.32%, #F2DA8E 9.37%, #F6DF97 10.38%, #FCF4CF 14.45%, #FFFFEB 16.48%, #FCF4CF 20.54%, #F6DF97 26.64%, #F1D88B 28.67%, #EACC77 32.73%, #E5C56B 35.78%, #E4C367 39.84%, #DDBA57 42.89%, #CDA32F 48.98%, #CB9F28 50%, #D6B045 55.08%, #E4C367 60.16%, #EACC77 63.21%, #F2DA8E 67.27%, #F6DF97 70.32%, #FCF4CF 75.4%, #FFFFEB 77.43%, #FDF9DC 78.44%, #F9EBB6 81.49%, #F6E29F 84.54%, #F6DF97 85.55%, #EED282 87.59%, #E4C367 90.63%, #D1A838 97.74%, #CB9F28 100.79%); */
  /* background: #d28f08; */
  /* background: linear-gradient(56.92deg, #795d11 -0.79%, #917016 1.24%, #E0BE5E 4.29%, #E4C367 5.3%, #EACC77 6.32%, #F2DA8E 9.37%, #F6DF97 10.38%, #f1da71 14.45%, #F2DA8E 16.48%, #F2DA8E 20.54%, #F6DF97 26.64%, #F1D88B 28.67%, #EACC77 32.73%, #E5C56B 35.78%, #E4C367 39.84%, #DDBA57 42.89%, #CDA32F 48.98%, #CB9F28 50%, #D6B045 55.08%, #E4C367 60.16%, #EACC77 63.21%, #F2DA8E 67.27%, #F6DF97 70.32%, #F2DA8E 75.4%, #F2DA8E 77.43%, #85690c 78.44%, #F9EBB6 81.49%, #F6E29F 84.54%, #F6DF97 85.55%, #EED282 87.59%, #E4C367 90.63%, #D1A838 97.74%, #CB9F28 100.79%); */
  background: linear-gradient(to right, #e5b350, #D7B047, #EACC77, #d2952a);
}
</style>
  