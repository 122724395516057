<script setup>
import { ref, computed } from 'vue'
import { useGameStore } from '../../stores/game.js'
import { useLiveGame } from '../../composables/useLiveGame.js'
import consumer from '../../../../javascript/channels/consumer.js'
import logo from '../logo/logo.vue'

const gameId = window.location.search.split('=')[1]
const gameChannel = consumer.subscriptions.create({ channel: "GameChannel", game_id: gameId }, {
  connected() {
    console.log('connected to game channel in family screen')
    this.load(gameId);
  },
  received(data) {
    window.eventBus.publish('onData', data)
    // Called when there's incoming data on the websocket for this channel
  },

  load(gameID) {
    this.perform("load", { game_id: gameID })
  }
})

const gameStore = useGameStore()
  
const score = computed(() => {
  if (props.scoreType === 'scoreA') {
    return gameStore.family_a_score
  }else{
    return gameStore.family_b_score
  }
})

const canShowFamilyLogo = computed(() => gameStore.family_screen_logo_visible)

const props = defineProps({
  scoreType: {
    type: String,
    required: true,
    validator: (value) => ['scoreA', 'scoreB'].includes(value)
  }
})
const initalized = computed(() => {
  const isInitialized = Object.keys(gameStore.gameState).length > 0
  console.log("game state in family screen", gameStore.gameState, isInitialized)
  return isInitialized
})
useLiveGame()

</script>

<template lang="pug">
.w-full
  .score-board.relative(v-if="initalized")
    logo(v-if="canShowFamilyLogo")
    .score-one.absolute(class="top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" v-else)
      p.score1Text.text-center.select-none(class='text-[#faa21b] text-[600px]' style="font-family: 'canal-light-romain', sans-serif;")
        | {{ score }}
</template>

<style scoped>
.score-board {
  background-image: url('../../assets/game/background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.score1Text,
.score2Text {
  text-shadow: 20px 10px 15px black;
}
</style>